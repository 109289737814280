export default {
  icons: {
    // techIcons
    "100tb": {
      exactName: "100TB",
      path: "100tb.svg",
    },
    "500px": {
      exactName: "500px",
      path: "500px.svg",
    },
    admob: {
      exactName: "Google Admob",
      path: "google_admob.svg",
    },
    adroll: {
      exactName: "AdRoll",
      path: "adroll.png",
    },
    adyen: {
      exactName: "Adyen",
      path: "adyen.svg",
    },
    aerospike: {
      exactName: "Aerospike",
      path: "aerospike.svg",
    },
    airbnb: {
      exactName: "Airbnb",
      path: "airbnb.svg",
    },
    airbrake: {
      exactName: "Airbrake",
      path: "airbrake.svg",
    },
    airflow: {
      exactName: "Apache Airflow",
      path: "airflow.svg",
    },
    airtable: {
      exactName: "Airtable",
      path: "airtable.svg",
    },
    akamai: {
      exactName: "Akamai",
      path: "akamai.svg",
    },
    akka: {
      exactName: "Akka",
      path: "akka.svg",
    },
    alfresco: {
      exactName: "Alfresco",
      path: "alfresco.svg",
    },
    algolia: {
      exactName: "Algolia",
      path: "algolia.svg",
    },
    altair: {
      exactName: "Altair",
      path: "altair.svg",
    },
    amazonchime: {
      exactName: "Amazon Chime",
      path: "amazon_chime.svg",
    },
    amazonconnect: {
      exactName: "Amazon Connect",
      path: "amazon_connect.svg",
    },
    americanexpress: {
      exactName: "AMERICAN EXPRESS",
      path: "amex.svg",
    },
    amex: {
      exactName: "AMERICAN EXPRESS",
      path: "amex.svg",
    },
    amp: {
      exactName: "AMP",
      path: "amp.svg",
    },
    ampersand: {
      exactName: "Ampersand",
      path: "ampersand.svg",
    },
    android: {
      exactName: "Android",
      path: "android.svg",
    },
    angellist: {
      exactName: "AngelList",
      path: "angellist.svg",
    },
    angular: {
      exactName: "Angular",
      path: "angular.svg",
    },
    angularjs: {
      exactName: "Angular",
      path: "angular.svg",
    },
    ansible: {
      exactName: "Ansible",
      path: "ansible.svg",
    },
    antdesign: {
      exactName: "Ant Design",
      path: "ant_design.svg",
    },
    apache: {
      exactName: "Apache",
      path: "apache.svg",
    },
    apacheairflow: {
      exactName: "Apache Airflow",
      path: "airflow.svg",
    },
    apachecamel: {
      exactName: "Apache Camel",
      path: "apache_camel.svg",
    },
    apachecloudstack: {
      exactName: "Apache Cloudstack",
      path: "apache_cloudstack.svg",
    },
    apachecordova: {
      exactName: "Apache Cordova",
      path: "cordova.svg",
    },
    apachehadoop: {
      exactName: "Apache Hadoop",
      path: "hadoop.svg",
    },
    apachehbase: {
      exactName: "Apache HBase",
      path: "hbase.svg",
    },
    apacheimpala: {
      exactName: "Apache Impala",
      path: "impala.svg",
    },
    apachekafka: {
      exactName: "Kafka",
      path: "kafka.svg",
    },
    apachemesos: {
      exactName: "Apache Mesos",
      path: "mesos.svg",
    },
    apachesolr: {
      exactName: "Apache Solr",
      path: "solr.svg",
    },
    apachetomcat: {
      exactName: "Apache Tomcat",
      path: "tomcat.svg",
    },
    apachewicket: {
      exactName: "Apache Wicket",
      path: "wicket.svg",
    },
    apiary: {
      exactName: "Apiary",
      path: "apiary.svg",
    },
    apollo: {
      exactName: "Apollo",
      path: "apollo.svg",
    },
    apostrophe: {
      exactName: "Apostrophe",
      path: "apostrophe.svg",
    },
    appbaseio: {
      exactName: "appbaseio.io",
      path: "appbaseio.svg",
    },
    appcircle: {
      exactName: "appcircle",
      path: "appcircle.svg",
    },
    appcode: {
      exactName: "AppCode",
      path: "appcode.svg",
    },
    appdynamics: {
      exactName: "Appdynamics",
      path: "appdynamics.svg",
    },
    appium: {
      exactName: "Appium",
      path: "appium.svg",
    },
    apple: {
      exactName: "Apple",
      path: "apple.svg",
    },
    appstore: {
      exactName: "App Store",
      path: "apple_app_store.svg",
    },
    appleappstore: {
      exactName: "App Store",
      path: "apple_app_store.svg",
    },
    appsignal: {
      exactName: "AppSignal",
      path: "appsignal.svg",
    },
    apptentive: {
      exactName: "Apptentive",
      path: "apptentive.svg",
    },
    appveyor: {
      exactName: "AppVeyor",
      path: "appveyor.svg",
    },
    arangodb: {
      exactName: "ArangoDB",
      path: "arangodb.svg",
    },
    archlinux: {
      exactName: "Arch Linux",
      path: "archlinux.svg",
    },
    arduino: {
      exactName: "Arduino",
      path: "arduino.svg",
    },
    armory: {
      exactName: "armory",
      path: "armory.svg",
    },
    asana: {
      exactName: "Asana",
      path: "asana.svg",
    },
    asciidoctor: {
      exactName: "Asciidoctor",
      path: "asciidoctor.svg",
    },
    astronomer: {
      exactName: "ASTRONOMER",
      path: "astronomer.svg",
    },
    atlassian: {
      exactName: "Atlassian",
      path: "atlassian.svg",
    },
    atom: {
      exactName: "Atom",
      path: "atom.svg",
    },
    atomic: {
      exactName: "atomic",
      path: "atomic.svg",
    },

    aurelia: {
      exactName: "aurelia",
      path: "aurelia.svg",
    },

    aurora: {
      exactName: "AURORA",
      path: "aurora.svg",
    },

    auth0: {
      exactName: "auth0",
      path: "auth0.svg",
    },
    authy: {
      exactName: "AUTHY",
      path: "authy.svg",
    },

    autoit: {
      exactName: "AUTOIT",
      path: "autoit.svg",
    },

    autoprefixer: {
      exactName: "Autoprefixer",
      path: "autoprefixer.svg",
    },

    ava: {
      exactName: "AVA",
      path: "ava.svg",
    },

    awesome: {
      exactName: "Awesome",
      path: "awesome.png",
    },

    amazonapigateway: {
      exactName: "Amazon API Gateway",
      path: "aws_api_gateway.svg",
    },

    amazoncloudfront: {
      exactName: "Amazon CloudFront",
      path: "aws_cloudfront.svg",
    },

    amazoncloudsearch: {
      exactName: "Amazon CloudSearch",
      path: "aws_cloudsearch.svg",
    },

    amazoncloudwatch: {
      exactName: "Amazon CloudWatch",
      path: "aws_cloudwatch.svg",
    },

    amazoncognito: {
      exactName: "Amazon Cognito",
      path: "aws_cognito.svg",
    },

    amazondynamodb: {
      exactName: "Amazon DynamoDB",
      path: "aws_dynamodb.svg",
    },

    amazonec2: {
      exactName: "Amazon EC2",
      path: "aws_ec2.svg",
    },

    amazonelasticcache: {
      exactName: "Amazon ElasticCache",
      path: "aws_elastic_cache.svg",
    },

    amazonglacier: {
      exactName: "Amazon Glacier",
      path: "aws_glacier.svg",
    },

    amazonkinesis: {
      exactName: "Amazon Kinesis",
      path: "aws_kinesis.svg",
    },
    
    amazonquicksight: {
      exactName: "Amazon QuickSight",
      path: "aws_quicksight.svg",
    },

    amazonrds: {
      exactName: "Amazon RDS",
      path: "aws_rds.svg",
    },

    amazonroute53: {
      exactName: "Amazon Route53",
      path: "aws_route53.svg",
    },

    amazons3: {
      exactName: "Amazon S3",
      path: "aws_s3.svg",
    },

    amazons3glacier: {
      exactName: "Amazon Glacier",
      path: "aws_glacier.svg",
    },

    amazonsns: {
      exactName: "Amazon SNS",
      path: "aws_sns.svg",
    },

    amazonsqs: {
      exactName: "Amazon SQS",
      path: "aws_sqs.svg",
    },

    awscloud9: {
      exactName: "AWS Cloud9",
      path: "cloud9.svg",
    },

    awscloudformation: {
      exactName: "AWS CloudFormation",
      path: "aws_cloudformation.svg",
    },

    awscodedeploy: {
      exactName: "AWS CodeDeploy",
      path: "aws_codedeploy.svg",
    },

    awsiam: {
      exactName: "AWS IAM",
      path: "aws_iam.svg",
    },

    awslambda: {
      exactName: "AWS Lambda",
      path: "aws_lambda.svg",
    },

    awsopsworks: {
      exactName: "AWS OpsWorks",
      path: "aws_opsworks.svg",
    },

    awsses: {
      exactName: "Amazon SES",
      path: "aws_ses.svg",
    },

    awswaf: {
      exactName: "AWS WAF",
      path: "aws_waf.svg",
    },

    aws: {
      exactName: "AWS",
      path: "aws.svg",
    },

    azure: {
      exactName: "Microsoft Azure",
      path: "azure.svg",
    },

    babel: {
      exactName: "Babel",
      path: "babel.svg",
    },

    backbone: {
      exactName: "BACKBONE.JS",
      path: "backbone.svg",
    },

    backbonejs: {
      exactName: "BACKBONE.JS",
      path: "backbone.svg",
    },

    backerkit: {
      exactName: "BACKERKIT",
      path: "backerkit.svg",
    },

    bakerstreet: {
      exactName: "BAKER STREET",
      path: "baker_street.svg",
    },

    balena: {
      exactName: "balena",
      path: "balena.svg",
    },

    bamboo: {
      exactName: "Bamboo",
      path: "bamboo.svg",
    },

    basecamp: {
      exactName: "Basecamp",
      path: "basecamp.svg",
    },

    basekit: {
      exactName: "BaseKit",
      path: "basekit.svg",
    },

    batch: {
      exactName: "batch",
      path: "batch.svg",
    },

    beats: {
      exactName: "Beats",
      path: "beats.svg",
    },

    behance: {
      exactName: "Behance",
      path: "behance.svg",
    },

    bembatman: {
      exactName: "BEM",
      path: "bem_batman.svg",
    },

    bem: {
      exactName: "BEM",
      path: "bem.svg",
    },

    bigpanda: {
      exactName: "BigPanda",
      path: "bigpanda.svg",
    },

    bing: {
      exactName: "Bing",
      path: "bing.svg",
    },

    bitbucket: {
      exactName: "Bitbucket",
      path: "bitbucket.svg",
    },

    bitcoin: {
      exactName: "Bitcoin",
      path: "bitcoin.svg",
    },

    bitnami: {
      exactName: "Bitnami",
      path: "bitnami.svg",
    },

    bitrise: {
      exactName: "Bitrise",
      path: "bitrise.svg",
    },

    blender: {
      exactName: "Blender",
      path: "blender.svg",
    },

    blocs: {
      exactName: "Blocs",
      path: "blocs.svg",
    },

    blogger: {
      exactName: "Blogger",
      path: "blogger.svg",
    },

    blossom: {
      exactName: "blossom",
      path: "blossom.svg",
    },

    blueprint: {
      exactName: "Blueprint",
      path: "blueprint.svg",
    },

    bluetooth: {
      exactName: "Bluetooth",
      path: "bluetooth.svg",
    },

    booqable: {
      exactName: "Booqable",
      path: "booqable.svg",
    },

    bootstrap: {
      exactName: "Bootstrap",
      path: "bootstrap.svg",
    },

    bosun: {
      exactName: "Bosun",
      path: "bosun.svg",
    },

    botanalytics: {
      exactName: "botanalytics",
      path: "botanalytics.svg",
    },

    bourbon: {
      exactName: "Bourbon",
      path: "bourbon.svg",
    },

    bower: {
      exactName: "Bower",
      path: "bower.svg",
    },

    box: {
      exactName: "box",
      path: "box.svg",
    },

    brackets: {
      exactName: "Brackets",
      path: "brackets.svg",
    },

    branch: {
      exactName: "Branch",
      path: "branch.svg",
    },

    brandfolder: {
      exactName: "Brandfolder",
      path: "brandfolder.svg",
    },

    brave: {
      exactName: "Brave",
      path: "brave.svg",
    },

    braze: {
      exactName: "Braze",
      path: "braze.svg",
    },

    broccoli: {
      exactName: "Broccoli",
      path: "broccoli.svg",
    },

    brotli: {
      exactName: "Brotli",
      path: "brotli.svg",
    },

    browserify: {
      exactName: "Browserify",
      path: "browserify.svg",
    },

    browserling: {
      exactName: "browserling",
      path: "browserling.svg",
    },

    browserslist: {
      exactName: "Browserslist",
      path: "browserslist.svg",
    },

    browserstack: {
      exactName: "BrowserStack",
      path: "browserstack.svg",
    },

    browsersync: {
      exactName: "Browsersync",
      path: "browsersync.svg",
    },

    brunch: {
      exactName: "Brunch",
      path: "brunch.svg",
    },

    buck: {
      exactName: "Buck",
      path: "buck.svg",
    },

    buddy: {
      exactName: "Buddy",
      path: "buddy.svg",
    },

    buffer: {
      exactName: "Buffer",
      path: "buffer.svg",
    },

    bugherd: {
      exactName: "BugHerd",
      path: "bugherd.svg",
    },

    bugsee: {
      exactName: "Bugsee",
      path: "bugsee.svg",
    },

    bugsnag: {
      exactName: "Bugsnag",
      path: "bugsnag.svg",
    },

    buildkite: {
      exactName: "Buildkite",
      path: "buildkite.svg",
    },

    bulma: {
      exactName: "Bulma",
      path: "bulma.svg",
    },

    cplusplus: {
      exactName: "C++",
      path: "c_plusplus.svg",
    },

    csharp: {
      exactName: "C#",
      path: "c_sharp.svg",
    },

    c: {
      exactName: "C",
      path: "c.svg",
    },

    cachet: {
      exactName: "Cachet",
      path: "cachet.svg",
    },

    caffe2: {
      exactName: "Caffe2",
      path: "caffe2.svg",
    },

    cakephp: {
      exactName: "CakePHP",
      path: "cakephp.svg",
    },

    campaignmonitor: {
      exactName: "Campaign Monitor",
      path: "campaignmonitor.svg",
    },

    canjs: {
      exactName: "CanJS",
      path: "canjs.svg",
    },

    capacitor: {
      exactName: "Capacitor",
      path: "capacitorjs.svg",
    },

    capacitorjs: {
      exactName: "Capacitor",
      path: "capacitorjs.svg",
    },

    capistrano: {
      exactName: "Capistrano",
      path: "capistrano.svg",
    },

    carbide: {
      exactName: "Carbide",
      path: "carbide.svg",
    },

    cassandra: {
      exactName: "Cassandra",
      path: "cassandra.svg",
    },

    centos: {
      exactName: "CentOS",
      path: "centos.svg",
    },

    certbot: {
      exactName: "Certbot",
      path: "certbot.png",
    },

    ceylon: {
      exactName: "Ceylon",
      path: "ceylon.svg",
    },

    chai: {
      exactName: "Chai",
      path: "chai.svg",
    },

    chalk: {
      exactName: "Chalk",
      path: "chalk.svg",
    },

    chargebee: {
      exactName: "Chargebee",
      path: "chargebee.svg",
    },

    chef: {
      exactName: "Chef",
      path: "chef.svg",
    },

    chevereto: {
      exactName: "chevereto",
      path: "chevereto.png",
    },

    chromatic: {
      exactName: "Chromatic",
      path: "chromatic.svg",
    },

    chrome: {
      exactName: "Google Chrome",
      path: "chrome.svg",
    },

    circleci: {
      exactName: "CircleCI",
      path: "circleci.svg",
    },

    cirrus: {
      exactName: "Cirrus",
      path: "cirrus.svg",
    },

    clion: {
      exactName: "CLion",
      path: "clion.svg",
    },

    cljs: {
      exactName: "ClojureScript",
      path: "cljs.svg",
    },

    clojurescript: {
      exactName: "ClojureScript",
      path: "cljs.svg",
    },

    clojure: {
      exactName: "Clojure",
      path: "clojure.svg",
    },

    close: {
      exactName: "Close",
      path: "close.svg",
    },

    cloud9: {
      exactName: "AWS Cloud9",
      path: "cloud9.svg",
    },

    cloudacademy: {
      exactName: "Cloud Academy",
      path: "cloudacademy.svg",
    },

    cloudbeescodeship: {
      exactName: "CloudBees CodeShip",
      path: "codeship.svg",
    },

    cloudcraft: {
      exactName: "Cloudcraft",
      path: "cloudcraft.svg",
    },

    cloudflare: {
      exactName: "Cloudflare",
      path: "cloudflare.svg",
    },

    cloudinary: {
      exactName: "Cloudinary",
      path: "cloudinary.svg",
    },

    cloudlinux: {
      exactName: "CloudLinux OS",
      path: "cloudlinux.svg",
    },

    cloudlinuxos: {
      exactName: "CloudLinux OS",
      path: "cloudlinux.svg",
    },

    cobalt: {
      exactName: "Cobalt",
      path: "cobalt.svg",
    },

    cockpit: {
      exactName: "Cockpit",
      path: "cockpit.svg",
    },

    cocoapods: {
      exactName: "CocoaPods",
      path: "cocoapods.svg",
    },

    codacy: {
      exactName: "Codacy",
      path: "codacy.svg",
    },

    code: {
      exactName: "code",
      path: "code.png",
    },

    codebase: {
      exactName: "Codebase",
      path: "codebase.png",
    },

    codebeat: {
      exactName: "codebeat",
      path: "codebeat.svg",
    },

    codecademy: {
      exactName: "Codecademy",
      path: "codecademy.svg",
    },

    codeception: {
      exactName: "Codeception",
      path: "codeception.svg",
    },

    codeclimate: {
      exactName: "Code Climate",
      path: "codeclimate.svg",
    },

    codecov: {
      exactName: "Codecov",
      path: "codecov.svg",
    },

    codefactor: {
      exactName: "CodeFactor",
      path: "codefactor.svg",
    },

    codeigniter: {
      exactName: "CodeIgniter",
      path: "codeigniter.svg",
    },

    codepen: {
      exactName: "CodePen",
      path: "codepen.svg",
    },

    codepush: {
      exactName: "CodePush",
      path: "codepush.svg",
    },

    codersrank: {
      exactName: "CodersRank",
      path: "codersrank.svg",
    },

    coderwall: {
      exactName: "Coderwall",
      path: "coderwall.svg",
    },

    codesandbox: {
      exactName: "CodeSandbox",
      path: "codesandbox.svg",
    },

    codeship: {
      exactName: "CloudBees CodeShip",
      path: "codeship.svg",
    },

    codio: {
      exactName: "Codio",
      path: "codio.svg",
    },

    codrops: {
      exactName: "Codrops",
      path: "codrops.svg",
    },

    coffeescript: {
      exactName: "CoffeeScript",
      path: "coffeescript.svg",
    },

    compass: {
      exactName: "Compass",
      path: "compass.svg",
    },

    componentkit: {
      exactName: "ComponentKit",
      path: "componentkit.svg",
    },

    compose: {
      exactName: "Compose",
      path: "compose.svg",
    },

    composer: {
      exactName: "Composer",
      path: "composer.svg",
    },

    conan: {
      exactName: "Conan",
      path: "conan_io.svg",
    },

    concourse: {
      exactName: "Concourse",
      path: "concourse.svg",
    },

    concrete5: {
      exactName: "concrete5",
      path: "concrete5.svg",
    },

    confluence: {
      exactName: "Confluence",
      path: "confluence.svg",
    },

    consul: {
      exactName: "Consul",
      path: "consul.svg",
    },

    contentful: {
      exactName: "Contentful",
      path: "contentful.svg",
    },

    convox: {
      exactName: "Convox",
      path: "convox.svg",
    },

    copyleftpirate: {
      exactName: "Copyleft Pirate",
      path: "copyleft_pirate.svg",
    },

    copyleft: {
      exactName: "Copyleft",
      path: "copyleft.svg",
    },

    cordova: {
      exactName: "Apache Cordova",
      path: "cordova.svg",
    },
    coreos: {
      exactName: "Core OS",
      path: "coreos.svg",
    },

    couchbase: {
      exactName: "Couchbase",
      path: "couchbase.svg",
    },

    couchdb: {
      exactName: "CouchDB",
      path: "couchdb.svg",
    },

    coursera: {
      exactName: "Coursera",
      path: "coursera.svg",
    },

    coveralls: {
      exactName: "Coveralls",
      path: "coveralls.svg",
    },

    cpanel: {
      exactName: "cPanel",
      path: "cpanel.png",
    },

    craftcms: {
      exactName: "Craft CMS",
      path: "craftcms.jpg",
    },

    crashlytics: {
      exactName: "Crashlytics",
      path: "crashlytics.svg",
    },

    crateio: {
      exactName: "CrateDB",
      path: "crateio.svg",
    },

    cratedb: {
      exactName: "CrateDB",
      path: "crateio.svg",
    },

    createjs: {
      exactName: "CreateJS",
      path: "createjs.svg",
    },

    crossbrowsertesting: {
      exactName: "CrossBrowserTesting",
      path: "cross_browser_testing.svg",
    },

    crucible: {
      exactName: "Crucible",
      path: "crucible.svg",
    },

    crystal: {
      exactName: "Crystal",
      path: "crystal.svg",
    },

    css: {
      exactName: "CSS",
      path: "css_3.svg",
    },

    css3: {
      exactName: "CSS",
      path: "css_3.svg",
    },

    cssnext: {
      exactName: "cssnext",
      path: "cssnext.svg",
    },

    cucumber: {
      exactName: "Cucumber",
      path: "cucumber.svg",
    },

    customerio: {
      exactName: "Customer.io",
      path: "customerio.svg",
    },

    cyclejs: {
      exactName: "Cycle.js",
      path: "cyclejs.svg",
    },

    cypress: {
      exactName: "Cypress",
      path: "cypress.svg",
    },

    cloudwatch: {
      exactName: "Amazon CloudWatch",
      path: "aws_cloudwatch.svg",
    },

    cloudfront: {
      exactName: "Amazon CloudFront",
      path: "aws_cloudfront.svg",
    },

    dynamodb: {
      exactName: "Amazon DynamoDB",
      path: "aws_dynamodb.svg",
    },

    d3: {
      exactName: "D3.js",
      path: "d3.svg",
    },

    d3js: {
      exactName: "D3.js",
      path: "d3.svg",
    },

    dart: {
      exactName: "Dart",
      path: "dart.svg",
    },

    dashlane: {
      exactName: "Dashlane",
      path: "dashlane.svg",
    },

    databaselabs: {
      exactName: "Database Labs",
      path: "database_labs.svg",
    },

    datadog: {
      exactName: "Datadog",
      path: "datadog.svg",
    },

    datocms: {
      exactName: "DatoCMS",
      path: "datocms.svg",
    },

    dcos: {
      exactName: "DC/OS",
      path: "dcos.svg",
    },

    debian: {
      exactName: "Debian",
      path: "debian.svg",
    },

    delighted: {
      exactName: "Delighted",
      path: "delighted.svg",
    },

    deno: {
      exactName: "Deno",
      path: "deno.svg",
    },

    deployhq: {
      exactName: "DeployHQ",
      path: "deployhq.svg",
    },

    derby: {
      exactName: "DerbyJS",
      path: "derby.svg",
    },

    derbyjs: {
      exactName: "DerbyJS",
      path: "derby.svg",
    },

    designernews: {
      exactName: "Designer News",
      path: "designernews.svg",
    },

    deviantart: {
      exactName: "DeviantArt",
      path: "deviantart.svg",
    },

    dialogflow: {
      exactName: "Dialogflow",
      path: "dialogflow.svg",
    },

    digitalocean: {
      exactName: "DigitalOcean",
      path: "digital_ocean.svg",
    },

    dimer: {
      exactName: "Dimer",
      path: "dimer.svg",
    },

    dinersclub: {
      exactName: "Diners Club",
      path: "dinersclub.svg",
    },

    discord: {
      exactName: "Discord",
      path: "discord.svg",
    },

    discover: {
      exactName: "Discover",
      path: "discover.svg",
    },

    disqus: {
      exactName: "Disqus",
      path: "disqus.svg",
    },

    django: {
      exactName: "Django",
      path: "django.svg",
    },

    dockbit: {
      exactName: "Dockbit",
      path: "dockbit.svg",
    },

    docker: {
      exactName: "Docker",
      path: "docker.svg",
    },

    doctrine: {
      exactName: "Doctrine",
      path: "doctrine.svg",
    },

    docusaurus: {
      exactName: "Docusaurus",
      path: "docusaurus.svg",
    },

    dojotoolkit: {
      exactName: "Dojo Toolkit",
      path: "dojo_toolkit.svg",
    },

    dojo: {
      exactName: "Dojo",
      path: "dojo.svg",
    },

    dotnet: {
      exactName: ".NET",
      path: "dotnet.svg",
    },

    dreamhost: {
      exactName: "DreamHost",
      path: "dreamhost.svg",
    },

    dribbble: {
      exactName: "Dribbble",
      path: "dribbble.svg",
    },

    drift: {
      exactName: "Drift",
      path: "drift.png",
    },

    drip: {
      exactName: "Drip",
      path: "drip.svg",
    },

    drone: {
      exactName: "Drone",
      path: "drone.svg",
    },

    dropbox: {
      exactName: "Dropbox",
      path: "dropbox.svg",
    },

    dropmark: {
      exactName: "Dropmark",
      path: "dropmark.svg",
    },
    dropzone: {
      exactName: "DropzoneJS",
      path: "dropzone.svg",
    },
    dropzonejs: {
      exactName: "DropzoneJS",
      path: "dropzone.svg",
    },
    drupal: {
      exactName: "Drupal",
      path: "drupal.svg",
    },
    duckduckgo: {
      exactName: "DuckDuckGo",
      path: "duckduckgo.svg",
    },
    dynatrace: {
      exactName: "Dynatrace",
      path: "dynatrace.svg",
    },
    dyndns: {
      exactName: "DynDNS",
      path: "dyndns.svg",
    },
    ebanx: {
      exactName: "EBANX",
      path: "ebanx.svg",
    },

    ec2: {
      exactName: "Amazon EC2",
      path: "aws_ec2.svg",
    },

    eclipse: {
      exactName: "Eclipse",
      path: "eclipse.svg",
    },
    editorconfig: {
      exactName: "EditorConfig",
      path: "editorconfig.svg",
    },
    egghead: {
      exactName: "egghead",
      path: "egghead.svg",
    },
    elasticpath: {
      exactName: "Elastic Path",
      path: "moltin.svg",
    },
    elasticsearch: {
      exactName: "Elasticsearch",
      path: "elasticsearch.svg",
    },
    electron: {
      exactName: "Electron",
      path: "electron.svg",
    },
    element: {
      exactName: "Element",
      path: "element.svg",
    },
    elementalui: {
      exactName: "Elemental UI",
      path: "elemental_ui.svg",
    },
    elementary: {
      exactName: "elementary OS",
      path: "elementary.svg",
    },
    elementaryos: {
      exactName: "elementary OS",
      path: "elementary.svg",
    },
    ello: {
      exactName: "Ello",
      path: "ello.svg",
    },
    elm: {
      exactName: "Elm",
      path: "elm.svg",
    },
    elo: {
      exactName: "Elo",
      path: "elo.svg",
    },
    emacs: {
      exactName: "Emacs",
      path: "emacs.svg",
    },
    embedly: {
      exactName: "Embedly",
      path: "embedly.svg",
    },
    embertomster: {
      exactName: "Ember",
      path: "ember_tomster.svg",
    },
    ember: {
      exactName: "Ember",
      path: "ember_tomster.svg",
    },
    emmet: {
      exactName: "Emmet",
      path: "emmet.svg",
    },
    engineyard: {
      exactName: "Engine Yard",
      path: "engine_yard.svg",
    },
    envato: {
      exactName: "Envato",
      path: "envato.svg",
    },
    envoyer: {
      exactName: "Envoyer",
      path: "envoyer.svg",
    },
    envoyproxy: {
      exactName: "Envoy Proxy",
      path: "envoyproxy.svg",
    },
    envoy: {
      exactName: "Envoy Proxy",
      path: "envoyproxy.svg",
    },
    enyo: {
      exactName: "Enyo",
      path: "enyo.png",
    },
    enyojs: {
      exactName: "Enyo",
      path: "enyo.png",
    },
    erlang: {
      exactName: "Erlang",
      path: "erlang.svg",
    },
    es6: {
      exactName: "ECMAScript 6(2015)",
      path: "es6.svg",
    },
    ecmascript6: {
      exactName: "ECMAScript 6(2015)",
      path: "es6.svg",
    },
    esbuild: {
      exactName: "esbuild",
      path: "esbuild.svg",
    },
    esdoc: {
      exactName: "ESDoc",
      path: "esdoc.svg",
    },
    eslintold: {
      exactName: "ESLint Old",
      path: "eslint_old.svg",
    },
    eslint: {
      exactName: "ESLint",
      path: "eslint.svg",
    },
    eta: {
      exactName: "Eta Programming Language",
      path: "eta_lang.svg",
    },
    etalang: {
      exactName: "Eta Programming Language",
      path: "eta_lang.svg",
    },
    etcd: {
      exactName: "etcd",
      path: "etcd.svg",
    },
    ethereum: {
      exactName: "Ethereum",
      path: "ethereum.svg",
    },
    ethnio: {
      exactName: "Ethnio",
      path: "ethnio.svg",
    },
    eventbrite: {
      exactName: "Eventbrite",
      path: "eventbrite.svg",
    },
    eventsentry: {
      exactName: "EventSentry",
      path: "eventsentry.svg",
    },
    evergreen: {
      exactName: "Evergreen",
      path: "evergreen.svg",
    },
    expo: {
      exactName: "Expo",
      path: "expo.svg",
    },
    express: {
      exactName: "Express",
      path: "express.svg",
    },
    fabric: {
      exactName: "Fabric",
      path: "fabric.svg",
    },
    facebook: {
      exactName: "Facebook",
      path: "facebook.svg",
    },
    falcor: {
      exactName: "Falcor",
      path: "falcor.svg",
    },
    fastify: {
      exactName: "Fastify",
      path: "fastify.svg",
    },
    fastlane: {
      exactName: "fastlane",
      path: "fastlane.svg",
    },
    fastly: {
      exactName: "Fastly",
      path: "fastly.svg",
    },
    feathersjs: {
      exactName: "Feathers",
      path: "feathersjs.svg",
    },
    feathers: {
      exactName: "Feathers",
      path: "feathersjs.svg",
    },
    fedora: {
      exactName: "Fedora",
      path: "fedora.svg",
    },
    figma: {
      exactName: "Figma",
      path: "figma.svg",
    },
    firebase: {
      exactName: "Firebase",
      path: "firebase.svg",
    },
    firefox: {
      exactName: "Firefox",
      path: "firefox.svg",
    },
    flannel: {
      exactName: "Flannel",
      path: "flannel.svg",
    },
    flarum: {
      exactName: "Flarum",
      path: "flarum.svg",
    },
    flask: {
      exactName: "Flask",
      path: "flask.svg",
    },
    flatui: {
      exactName: "Flat UI",
      path: "flat_ui.svg",
    },
    flattr: {
      exactName: "Flattr",
      path: "flattr.svg",
    },
    fleep: {
      exactName: "Fleep",
      path: "fleep.svg",
    },
    flickr: {
      exactName: "Flickr",
      path: "flickr.svg",
    },
    flight: {
      exactName: "Flight",
      path: "flight.svg",
    },
    floodio: {
      exactName: "Flood",
      path: "floodio.svg",
    },
    flood: {
      exactName: "Flood",
      path: "floodio.svg",
    },
    flow: {
      exactName: "Flow",
      path: "flow.svg",
    },
    flowxo: {
      exactName: "Flow XO",
      path: "flowxo.svg",
    },
    floydhub: {
      exactName: "FloydHub",
      path: "floydhub.svg",
    },
    flutter: {
      exactName: "Flutter",
      path: "flutter.svg",
    },
    flux: {
      exactName: "Flux",
      path: "flux.svg",
    },
    fluxxor: {
      exactName: "Fluxxor",
      path: "fluxxor.svg",
    },
    fly: {
      exactName: "Fly",
      path: "fly.svg",
    },
    flyio: {
      exactName: "Fly",
      path: "fly.svg",
    },
    fogbugz: {
      exactName: "FogBugz",
      path: "manuscript.svg",
    },
    fomo: {
      exactName: "Fomo",
      path: "fomo.png",
    },
    fontawesome: {
      exactName: "Font Awesome",
      path: "font_awesome.svg",
    },
    forestadmin: {
      exactName: "Forest Admin",
      path: "forestadmin.svg",
    },
    forever: {
      exactName: "forever",
      path: "forever.svg",
    },
    formkeep: {
      exactName: "FormKeep",
      path: "formkeep.svg",
    },
    foundation: {
      exactName: "Foundation",
      path: "foundation.svg",
    },
    framework7: {
      exactName: "Framework7",
      path: "framework7.svg",
    },
    freebsd: {
      exactName: "FreeBSD",
      path: "freebsd.svg",
    },
    freedcamp: {
      exactName: "Freedcamp",
      path: "freedcamp.svg",
    },
    freedomdefined: {
      exactName: "Free Cultural Works",
      path: "freedomdefined.svg",
    },
    freeculturalworks: {
      exactName: "Free Cultural Works",
      path: "freedomdefined.svg",
    },
    frontapp: {
      exactName: "Front",
      path: "frontapp.svg",
    },
    front: {
      exactName: "Front",
      path: "frontapp.svg",
    },
    fsharp: {
      exactName: "F#",
      path: "fsharp.svg",
    },
    fuchsia: {
      exactName: "Fuchsia",
      path: "fuchsia.svg",
    },
    galliumos: {
      exactName: "GalliumOS",
      path: "galliumos.svg",
    },
    gameanalytics: {
      exactName: "GameAnalytics",
      path: "game_analytics.svg",
    },
    gatsby: {
      exactName: "Gatsby",
      path: "gatsby.svg",
    },
    gatsbyjs: {
      exactName: "Gatsby",
      path: "gatsby.svg",
    },
    gcp: {
      exactName: "google Cloud",
      path: "google_cloud.svg",
    },
    geekbot: {
      exactName: "Geekbot",
      path: "geekbot.svg",
    },
    getyourguide: {
      exactName: "GetYourGuide",
      path: "get_your_guide.png",
    },
    ghost: {
      exactName: "Ghost",
      path: "ghost.png",
    },
    giantswarm: {
      exactName: "Giant Swarm",
      path: "giantswarm.svg",
    },
    git: {
      exactName: "Git",
      path: "git.svg",
    },
    gitboard: {
      exactName: "Gitboard",
      path: "gitboard.svg",
    },
    github: {
      exactName: "Github",
      path: "github_octocat.svg",
    },
    gitkraken: {
      exactName: "GitKraken",
      path: "gitkraken.svg",
    },
    gitlab: {
      exactName: "GitLab",
      path: "gitlab.svg",
    },
    gitter: {
      exactName: "Gitter",
      path: "gitter.svg",
    },
    gitup: {
      exactName: "GitUp",
      path: "gitup.svg",
    },
    glamorous: {
      exactName: "glamorous",
      path: "glamorous.svg",
    },
    gleam: {
      exactName: "Gleam",
      path: "gleam.svg",
    },
    glimmerjs: {
      exactName: "Glimmer",
      path: "glimmerjs.svg",
    },
    glimmer: {
      exactName: "Glimmer",
      path: "glimmerjs.svg",
    },
    glint: {
      exactName: "Glint",
      path: "glint.png",
    },
    glitch: {
      exactName: "Glitch",
      path: "glitch.svg",
    },
    gnu: {
      exactName: "GNU",
      path: "gnu.svg",
    },
    go: {
      exactName: "Go",
      path: "go.svg",
    },
    golang: {
      exactName: "Go",
      path: "go.svg",
    },
    gocd: {
      exactName: "GoCD",
      path: "gocd.png",
    },
    gohorse: {
      exactName: "Go Horse",
      path: "gohorse.svg",
    },
    google360suite: {
      exactName: "Google Analytics 360 Suite",
      path: "google_360suite.svg",
    },
    googleanalytics360suite: {
      exactName: "Google Analytics 360 Suite",
      path: "google_360suite.svg",
    },
    googleadmob: {
      exactName: "Google Admob",
      path: "google_admob.svg",
    },
    googleads: {
      exactName: "Google Ads",
      path: "google_ads.svg",
    },
    googleadsense: {
      exactName: "Google AdSense",
      path: "google_adsense.svg",
    },
    googleanalytics: {
      exactName: "Google Analytics",
      path: "google_analytics.svg",
    },
    googlecalendar: {
      exactName: "Google Calendar",
      path: "google_calendar.svg",
    },
    googlecloudfunctions: {
      exactName: "Google Cloud Functions",
      path: "google_cloud_functions.svg",
    },
    googlecloudrun: {
      exactName: "Google Cloud Run",
      path: "google_cloud_run.svg",
    },
    googlecloud: {
      exactName: "google Cloud",
      path: "google_cloud.svg",
    },
    googlecloudplatform: {
      exactName: "google Cloud",
      path: "google_cloud.svg",
    },
    googlecurrents: {
      exactName: "Google Currents",
      path: "google_currents.svg",
    },
    googledatastudio: {
      exactName: "Google Data Studio",
      path: "google_data_studio.svg",
    },
    googledevelopers: {
      exactName: "Google Developers",
      path: "google_developers.svg",
    },
    googledrive: {
      exactName: "Google Drive",
      path: "google_drive.svg",
    },
    googlefit: {
      exactName: "Google Fit",
      path: "google_fit.svg",
    },
    googlegmail: {
      exactName: "Google Gmail",
      path: "google_gmail.svg",
    },
    googlehome: {
      exactName: "Google Home",
      path: "google_home.svg",
    },
    googlekeep: {
      exactName: "google_keep",
      path: "google_keep.svg",
    },
    googlemaps: {
      exactName: "Google Maps",
      path: "google_maps.svg",
    },
    googlemarketingplatform: {
      exactName: "Google Marketing Platform",
      path: "google_marketing_platform.svg",
    },
    googlemeet: {
      exactName: "Google Meet",
      path: "google_meet.svg",
    },
    googleone: {
      exactName: "Google One",
      path: "google_one.svg",
    },
    googleoptimize: {
      exactName: "Google Optimize",
      path: "google_optimize.svg",
    },
    googlepay: {
      exactName: "Google Pay",
      path: "google_pay.svg",
    },
    googlephotos: {
      exactName: "Google Photos",
      path: "google_photos.svg",
    },
    googleplay: {
      exactName: "Google Play",
      path: "google_play.svg",
    },
    googletagmanager: {
      exactName: "Google Tag Manager",
      path: "google_tag_manager.svg",
    },
    google: {
      exactName: "Google",
      path: "google.svg",
    },
    gmail: {
      exactName: "Google Gmail",
      path: "google_gmail.svg",
    },
    gradle: {
      exactName: "Gradle",
      path: "gradle.svg",
    },
    grafana: {
      exactName: "Grafana",
      path: "grafana.svg",
    },
    grails: {
      exactName: "Grails",
      path: "grails.svg",
    },
    graphene: {
      exactName: "Graphene_Python",
      path: "graphene.svg",
    },
    graphenepython: {
      exactName: "Graphene_Python",
      path: "graphene.svg",
    },
    graphql: {
      exactName: "GraphQL",
      path: "graphql.svg",
    },
    grav: {
      exactName: "Grav",
      path: "grav.svg",
    },
    gravatar: {
      exactName: "Gravatar",
      path: "gravatar.svg",
    },
    graylog: {
      exactName: "Graylog",
      path: "graylog.svg",
    },
    gridsome: {
      exactName: "Gridsome",
      path: "gridsome.svg",
    },
    grommet: {
      exactName: "grommet",
      path: "grommet.svg",
    },
    groovehq: {
      exactName: "Groove",
      path: "groovehq.svg",
    },
    groove: {
      exactName: "Groove",
      path: "groovehq.svg",
    },
    grove: {
      exactName: "Grove",
      path: "grove.svg",
    },
    grunt: {
      exactName: "Grunt",
      path: "grunt.svg",
    },
    gulp: {
      exactName: "gulp",
      path: "gulp.svg",
    },
    gulpjs: {
      exactName: "gulp",
      path: "gulp.svg",
    },
    gunicorn: {
      exactName: "Gunicorn",
      path: "gunicorn.svg",
    },
    gusto: {
      exactName: "Gusto",
      path: "gusto.svg",
    },
    gwt: {
      exactName: "GWT",
      path: "gwt.svg",
    },
    hack: {
      exactName: "Hack",
      path: "hack.svg",
    },
    hackerone: {
      exactName: "HackerOne",
      path: "hacker_one.svg",
    },
    hadoop: {
      exactName: "Apache Hadoop",
      path: "hadoop.svg",
    },
    haiku: {
      exactName: "Haiku",
      path: "haiku.svg",
    },
    haml: {
      exactName: "Haml",
      path: "haml.svg",
    },
    hanami: {
      exactName: "Hanami",
      path: "hanami.svg",
    },
    handlebars: {
      exactName: "Handlebars",
      path: "handlebars.svg",
    },
    hapi: {
      exactName: "hapi",
      path: "hapi.png",
    },
    hashnode: {
      exactName: "Hashnode",
      path: "hashnode.svg",
    },
    haskell: {
      exactName: "Haskell",
      path: "haskell.svg",
    },
    hasura: {
      exactName: "Hasura",
      path: "hasura.svg",
    },
    haxe: {
      exactName: "Haxe",
      path: "haxe.svg",
    },
    haxl: {
      exactName: "Haxl",
      path: "haxl.svg",
    },
    hbase: {
      exactName: "Apache HBase",
      path: "hbase.svg",
    },
    heap: {
      exactName: "Heap",
      path: "heap.svg",
    },
    helpscout: {
      exactName: "Help Scout",
      path: "helpscout.svg",
    },
    herokuredis: {
      exactName: "Heroku Redis",
      path: "heroku_redis.svg",
    },
    heroku: {
      exactName: "Heroku",
      path: "heroku.svg",
    },
    hexo: {
      exactName: "Hexo",
      path: "hexo.svg",
    },
    hhvm: {
      exactName: "HHVM",
      path: "hhvm.svg",
    },
    hibernate: {
      exactName: "Hibernate",
      path: "hibernate.svg",
    },
    highcharts: {
      exactName: "Highcharts",
      path: "highcharts.svg",
    },
    hipercard: {
      exactName: "Hipercard",
      path: "hipercard.svg",
    },
    hoa: {
      exactName: "Hoa",
      path: "hoa.svg",
    },
    homebrew: {
      exactName: "Homebrew",
      path: "homebrew.svg",
    },
    hoodie: {
      exactName: "Hoodie",
      path: "hoodie.svg",
    },
    hostedgraphite: {
      exactName: "Hosted Graphite",
      path: "hosted_graphite.svg",
    },
    hostgator: {
      exactName: "HostGator",
      path: "hostgator.svg",
    },
    hotjar: {
      exactName: "Hotjar",
      path: "hotjar.svg",
    },
    houndci: {
      exactName: "Hound",
      path: "houndci.svg",
    },
    hound: {
      exactName: "Hound",
      path: "houndci.svg",
    },
    html: {
      exactName: "HTML",
      path: "html_5.svg",
    },
    html5: {
      exactName: "HTML",
      path: "html_5.svg",
    },
    html5boilerplate: {
      exactName: "HTML5 Boilerplate",
      path: "html5_boilerplate.svg",
    },
    hubspot: {
      exactName: "HubSpot",
      path: "hubspot.svg",
    },
    hugo: {
      exactName: "Hugo",
      path: "hugo.png",
    },
    humongous: {
      exactName: "HumongouS.io",
      path: "humongous.svg",
    },
    humongousio: {
      exactName: "HumongouS.io",
      path: "humongous.svg",
    },
    hyper: {
      exactName: "Hyper",
      path: "hyper.svg",
    },
    hyperapp: {
      exactName: "Hyperapp",
      path: "hyperapp.jpg",
    },
    ibm: {
      exactName: "IBM",
      path: "ibm.svg",
    },
    ieee: {
      exactName: "IEEE",
      path: "ieee.svg",
    },
    ifttt: {
      exactName: "IFTTT",
      path: "ifttt.svg",
    },
    imagemin: {
      exactName: "imagemin",
      path: "imagemin.svg",
    },
    immutable: {
      exactName: "Immutable",
      path: "immutable.png",
    },
    immutablejs: {
      exactName: "Immutable",
      path: "immutable.png",
    },
    impala: {
      exactName: "Apache Impala",
      path: "impala.svg",
    },
    importio: {
      exactName: "Import.io",
      path: "importio.png",
    },
    infer: {
      exactName: "Infer",
      path: "infer.svg",
    },
    inferno: {
      exactName: "Inferno",
      path: "inferno.svg",
    },
    influxdb: {
      exactName: "InfluxDB",
      path: "influxdb.svg",
    },
    ink: {
      exactName: "Ink",
      path: "ink.svg",
    },
    insomnia: {
      exactName: "Insomnia",
      path: "insomnia.svg",
    },
    instagram: {
      exactName: "Instagram",
      path: "instagram.svg",
    },
    intellijidea: {
      exactName: "IntelliJ IDEA",
      path: "intellij_idea.svg",
    },
    intercom: {
      exactName: "Intercom",
      path: "intercom.svg",
    },
    internetexplorer: {
      exactName: "Internet Explorer",
      path: "internetexplorer.svg",
    },
    ie: {
      exactName: "Internet Explorer",
      path: "internetexplorer.svg",
    },
    invision: {
      exactName: "invision",
      path: "invision.svg",
    },
    ionic: {
      exactName: "Ionic",
      path: "ionic.svg",
    },
    iron: {
      exactName: "Iron",
      path: "iron.svg",
    },
    itsalive: {
      exactName: "ItsAlive",
      path: "itsalive.svg",
    },
    jade: {
      exactName: "jade",
      path: "jade.svg",
    },
    jamstack: {
      exactName: "Jamstack",
      path: "jamstack.svg",
    },
    jasmine: {
      exactName: "Jasmine",
      path: "jasmine.svg",
    },
    java: {
      exactName: "Java",
      path: "java.svg",
    },
    javascript: {
      exactName: "JavaScript",
      path: "js.svg",
    },
    jcb: {
      exactName: "JCB",
      path: "jcb.svg",
    },
    jekyll: {
      exactName: "Jekyll",
      path: "jekyll.svg",
    },
    jelastic: {
      exactName: "Jelastic",
      path: "jelastic.svg",
    },
    jenkins: {
      exactName: "Jenkins",
      path: "jenkins.svg",
    },
    jest: {
      exactName: "Jest",
      path: "jest.svg",
    },
    jetbrains: {
      exactName: "JetBrains",
      path: "jetbrains.svg",
    },
    jfrog: {
      exactName: "JFrog",
      path: "jfrog.svg",
    },
    jhipster: {
      exactName: "JHipster",
      path: "jhipster.svg",
    },
    jira: {
      exactName: "Jira",
      path: "jira.svg",
    },

    jinja: {
      exactName: "Jinja",
      path: "jinja.svg",
    },

    jinja2: {
      exactName: "Jinja",
      path: "jinja.svg",
    },

    joomla: {
      exactName: "Joomla!",
      path: "joomla.svg",
    },
    jquerymobile: {
      exactName: "jQuery Mobile",
      path: "jquery_mobile.svg",
    },
    jquery: {
      exactName: "jQuery",
      path: "jquery.svg",
    },
    jruby: {
      exactName: "JRuby",
      path: "jruby.svg",
    },
    js: {
      exactName: "JavaScript",
      path: "js.svg",
    },
    jsbin: {
      exactName: "JS Bin",
      path: "jsbin.svg",
    },
    jsdelivr: {
      exactName: "jsDelivr",
      path: "jsdelivr.svg",
    },
    jsdom: {
      exactName: "jsdom",
      path: "jsdom.svg",
    },
    jsfiddle: {
      exactName: "JSFiddle",
      path: "jsfiddle.svg",
    },
    json: {
      exactName: "JSON",
      path: "json.svg",
    },
    jspm: {
      exactName: "JSPM",
      path: "jspm.svg",
    },
    jss: {
      exactName: "JSS",
      path: "jss.svg",
    },
    juju: {
      exactName: "Juju",
      path: "juju.svg",
    },
    julia: {
      exactName: "Julia",
      path: "julia.svg",
    },
    jupyter: {
      exactName: "Jupyter",
      path: "jupyter.svg",
    },
    jupyternotebook: {
      exactName: "Jupyter",
      path: "jupyter.svg",
    },
    kafka: {
      exactName: "Kafka",
      path: "kafka.svg",
    },
    kaios: {
      exactName: "KaiOS",
      path: "kaios.png",
    },
    kallithea: {
      exactName: "Kallithea",
      path: "kallithea.svg",
    },
    karma: {
      exactName: "Karma",
      path: "karma.svg",
    },
    kde: {
      exactName: "KDE",
      path: "kde.svg",
    },
    keen: {
      exactName: "Keen",
      path: "keen.png",
    },
    kemal: {
      exactName: "Kemal",
      path: "kemal.svg",
    },
    keycdn: {
      exactName: "KeyCDN",
      path: "keycdn.svg",
    },
    keystonejs: {
      exactName: "KeystoneJS",
      path: "keystonejs.svg",
    },
    khanacademy: {
      exactName: "Khan Academy",
      path: "khan_academy.svg",
    },
    kibana: {
      exactName: "Kibana",
      path: "kibana.svg",
    },
    kickstarter: {
      exactName: "Kickstarter",
      path: "kickstarter.svg",
    },
    kinto: {
      exactName: "Kinto",
      path: "kinto.svg",
    },
    kirby: {
      exactName: "Kirby",
      path: "kirby.svg",
    },
    kissmetrics: {
      exactName: "KISSmetrics",
      path: "kissmetrics.svg",
    },
    kitematic: {
      exactName: "Kitematic",
      path: "kitematic.svg",
    },
    kloudless: {
      exactName: "Kloudless",
      path: "kloudless.svg",
    },
    knex: {
      exactName: "Knex.js",
      path: "knex.svg",
    },
    knexjs: {
      exactName: "Knex.js",
      path: "knex.svg",
    },
    knockout: {
      exactName: "Knockout",
      path: "knockout.png",
    },
    koa: {
      exactName: "Koa",
      path: "koa.svg",
    },
    kong: {
      exactName: "Kong",
      path: "kong.svg",
    },
    kops: {
      exactName: "kOps",
      path: "kops.svg",
    },
    kore: {
      exactName: "Kore",
      path: "koreio.svg",
    },
    koreio: {
      exactName: "Kore",
      path: "koreio.svg",
    },
    kotlin: {
      exactName: "Kotlin",
      path: "kotlin.svg",
    },
    kraken: {
      exactName: "Kraken.io",
      path: "kraken.svg",
    },
    krakenio: {
      exactName: "Kraken.io",
      path: "kraken.svg",
    },
    krakenjs: {
      exactName: "krakenjs",
      path: "krakenjs.svg",
    },
    kubernetes: {
      exactName: "Kubernetes",
      path: "kubernetes.svg",
    },
    kustomer: {
      exactName: "kustomer",
      path: "kustomer.png",
    },
    laravel: {
      exactName: "Laravel",
      path: "laravel.svg",
    },
    lastfm: {
      exactName: "Last.fm",
      path: "last_fm.svg",
    },
    lateral: {
      exactName: "Lateral",
      path: "lateral.svg",
    },
    launchrock: {
      exactName: "Launchrock",
      path: "launchrock.svg",
    },
    leaflet: {
      exactName: "Leaflet",
      path: "leaflet.png",
    },
    leankit: {
      exactName: "LeanKit",
      path: "leankit.svg",
    },
    less: {
      exactName: "Less",
      path: "less.svg",
    },
    letscloud: {
      exactName: "LetsCloud",
      path: "lets_cloud.svg",
    },
    letsencrypt: {
      exactName: "Let's Encrypt",
      path: "letsencrypt.svg",
    },
    leveldb: {
      exactName: "leveldb",
      path: "leveldb.svg",
    },
    liftweb: {
      exactName: "Lift",
      path: "liftweb.svg",
    },
    lift: {
      exactName: "Lift",
      path: "liftweb.svg",
    },
    lightbend: {
      exactName: "Lightbend",
      path: "lightbend.svg",
    },
    lighthouse: {
      exactName: "Lighthouse",
      path: "lighthouse.svg",
    },
    lighttpd: {
      exactName: "Lighttpd",
      path: "lighttpd.svg",
    },
    linkedin: {
      exactName: "LinkedIn",
      path: "linkedin.svg",
    },
    linkerd: {
      exactName: "Linkerd",
      path: "linkerd.svg",
    },
    linode: {
      exactName: "Linode",
      path: "linode.svg",
    },
    linuxmint: {
      exactName: "Linux Mint",
      path: "linux_mint.svg",
    },
    linuxtux: {
      exactName: "Linux",
      path: "linux_tux.svg",
    },
    linux: {
      exactName: "Linux",
      path: "linux_tux.svg",
    },
    litmus: {
      exactName: "Litmus",
      path: "litmus.svg",
    },
    loader: {
      exactName: "Loader.io",
      path: "loader.svg",
    },
    loaderio: {
      exactName: "Loader.io",
      path: "loader.svg",
    },
    lodash: {
      exactName: "Lodash",
      path: "lodash.svg",
    },
    logentries: {
      exactName: "Logentries",
      path: "logentries.svg",
    },
    logstash: {
      exactName: "Logstash",
      path: "logstash.svg",
    },
    lookback: {
      exactName: "Lookback",
      path: "lookback.svg",
    },
    looker: {
      exactName: "Looker",
      path: "looker.svg",
    },
    loom: {
      exactName: "Loom",
      path: "loom.png",
    },
    loopback: {
      exactName: "LoopBack",
      path: "loopback.svg",
    },
    losant: {
      exactName: "Losant",
      path: "losant.svg",
    },
    lua: {
      exactName: "Lua",
      path: "lua.svg",
    },
    lucenenet: {
      exactName: "Lucene.NET",
      path: "lucene_net.svg",
    },
    lucene: {
      exactName: "Lucene",
      path: "lucene.png",
    },
    lumen: {
      exactName: "Lumen",
      path: "lumen.svg",
    },
    madge: {
      exactName: "Madge",
      path: "madge.svg",
    },
    maestro: {
      exactName: "Maestro",
      path: "maestro.svg",
    },
    mageia: {
      exactName: "Mageia",
      path: "mageia.svg",
    },
    magento: {
      exactName: "Magento",
      path: "magento.svg",
    },
    mailchimpfreddie: {
      exactName: "Mailchimp",
      path: "mailchimp_freddie.svg",
    },
    mailchimp: {
      exactName: "Mailchimp",
      path: "mailchimp_freddie.svg",
    },
    maildeveloper: {
      exactName: "MailDeveloper",
      path: "maildeveloper.svg",
    },
    mailgun: {
      exactName: "Mailgun",
      path: "mailgun.svg",
    },
    mailjet: {
      exactName: "Mailjet",
      path: "mailjet.svg",
    },
    manuscript: {
      exactName: "FogBugz",
      path: "manuscript.svg",
    },
    mapbox: {
      exactName: "Mapbox",
      path: "mapbox.svg",
    },
    mapsme: {
      exactName: "Maps.me",
      path: "maps_me.svg",
    },
    mapzen: {
      exactName: "Mapzen",
      path: "mapzen.svg",
    },
    mariadb: {
      exactName: "MariaDB",
      path: "mariadb.svg",
    },
    marionette: {
      exactName: "Marionette",
      path: "marionette.svg",
    },
    markdown: {
      exactName: "Markdown",
      path: "markdown.svg",
    },
    marko: {
      exactName: "Marko",
      path: "marko.svg",
    },
    marvel: {
      exactName: "Marvel",
      path: "marvel.svg",
    },
    mastercard: {
      exactName: "Mastercard",
      path: "mastercard.svg",
    },
    mastodon: {
      exactName: "Mastodon",
      path: "mastodon.svg",
    },
    materialui: {
      exactName: "Material_UI",
      path: "material_ui.svg",
    },
    materializecss: {
      exactName: "Materialize",
      path: "materializecss.svg",
    },
    materialize: {
      exactName: "Materialize",
      path: "materializecss.svg",
    },
    mattermost: {
      exactName: "mattermost",
      path: "mattermost.svg",
    },
    maven: {
      exactName: "maven",
      path: "maven.svg",
    },
    maxcdn: {
      exactName: "MaxCDN",
      path: "maxcdn.svg",
    },
    mdn: {
      exactName: "MDN",
      path: "mdn.svg",
    },
    mdx: {
      exactName: "MDX",
      path: "mdx.svg",
    },
    medium: {
      exactName: "Medium",
      path: "medium.svg",
    },
    memcached: {
      exactName: "Memcached",
      path: "memcached.svg",
    },
    memsql: {
      exactName: "SingleStore",
      path: "memsql.svg",
    },
    singlestore: {
      exactName: "SingleStore",
      path: "memsql.svg",
    },
    mention: {
      exactName: "Mention",
      path: "mention.png",
    },
    mercurial: {
      exactName: "Mercurial",
      path: "mercurial.svg",
    },
    mesos: {
      exactName: "Apache Mesos",
      path: "mesos.svg",
    },
    metabase: {
      exactName: "Metabase",
      path: "metabase.svg",
    },
    meteor: {
      exactName: "Meteor",
      path: "meteor.svg",
    },
    microcosm: {
      exactName: "Microcosm",
      path: "microcosm.svg",
    },
    microsoftedge: {
      exactName: "Microsoft Edge",
      path: "microsoft_edge.svg",
    },
    microsoftwindows: {
      exactName: "Microsoft Windows",
      path: "microsoft_windows.svg",
    },
    microsoft: {
      exactName: "Microsoft",
      path: "microsoft.svg",
    },
    middleman: {
      exactName: "Middleman",
      path: "middleman.svg",
    },
    milligram: {
      exactName: "Milligram",
      path: "milligram.svg",
    },
    mio: {
      exactName: "Mio",
      path: "mio.png",
    },
    mist: {
      exactName: "Mist",
      path: "mist.svg",
    },
    mithril: {
      exactName: "Mithril",
      path: "mithril.svg",
    },
    mithriljs: {
      exactName: "Mithril",
      path: "mithril.svg",
    },
    mixmax: {
      exactName: "Mixmax",
      path: "mixmax.svg",
    },
    mixpanel: {
      exactName: "Mixpanel",
      path: "mixpanel.svg",
    },
    mlab: {
      exactName: "mLab",
      path: "mlab.svg",
    },
    mobx: {
      exactName: "MobX",
      path: "mobx.svg",
    },
    mocha: {
      exactName: "Mocha",
      path: "mocha.svg",
    },
    mockflow: {
      exactName: "MockFlow",
      path: "mockflow.svg",
    },
    modernizr: {
      exactName: "Modernizr",
      path: "modernizr.svg",
    },
    modx: {
      exactName: "MODX",
      path: "modx.svg",
    },
    moltin: {
      exactName: "Elastic Path",
      path: "moltin.svg",
    },
    momentjs: {
      exactName: "Moment.js",
      path: "momentjs.svg",
    },
    monday: {
      exactName: "monday.com",
      path: "monday.svg",
    },
    mondaycom: {
      exactName: "monday.com",
      path: "monday.svg",
    },
    monero: {
      exactName: "Monero",
      path: "monero.svg",
    },
    mongodb: {
      exactName: "MongoDB",
      path: "mongodb.svg",
    },
    mono: {
      exactName: "Mono",
      path: "mono.svg",
    },
    moon: {
      exactName: "Moon",
      path: "moon.svg",
    },
    mootools: {
      exactName: "MooTools",
      path: "mootools.png",
    },
    morpheus: {
      exactName: "Morpheus",
      path: "morpheus.svg",
    },
    mozilla: {
      exactName: "Mozilla",
      path: "mozilla.svg",
    },
    mparticle: {
      exactName: "mParticle",
      path: "mparticle.svg",
    },
    mysql: {
      exactName: "MySQL",
      path: "mysql.svg",
    },
    namecheap: {
      exactName: "Namecheap",
      path: "namecheap.svg",
    },
    nanonets: {
      exactName: "Nanonets",
      path: "nanonets.svg",
    },
    nativescript: {
      exactName: "NativeScript",
      path: "nativescript.svg",
    },
    neat: {
      exactName: "Neat",
      path: "neat.svg",
    },
    neo4j: {
      exactName: "Neo4j",
      path: "neo4j.svg",
    },
    neovim: {
      exactName: "Neovim",
      path: "neovim.svg",
    },
    nestjs: {
      exactName: "Nest",
      path: "nestjs.svg",
    },
    nest: {
      exactName: "Nest",
      path: "nestjs.svg",
    },
    netbeans: {
      exactName: "NetBeans",
      path: "netbeans.svg",
    },
    netflix: {
      exactName: "Netflix",
      path: "netflix.svg",
    },
    netlify: {
      exactName: "Netlify",
      path: "netlify.svg",
    },
    newrelic: {
      exactName: "New Relic",
      path: "new_relic.svg",
    },
    nextjs: {
      exactName: "Next.js",
      path: "nextjs.svg",
    },
    nginx: {
      exactName: "Nginx",
      path: "nginx.svg",
    },
    nightwatch: {
      exactName: "Nightwatch.js",
      path: "nightwatch.svg",
    },
    nodal: {
      exactName: "Nodal",
      path: "nodal.svg",
    },
    nodesass: {
      exactName: "node_sass",
      path: "node_sass.svg",
    },
    nodebots: {
      exactName: "NodeBots",
      path: "nodebots.svg",
    },
    nodejs: {
      exactName: "Node.js",
      path: "nodejs.svg",
    },
    nodemon: {
      exactName: "Nodemon",
      path: "nodemon.svg",
    },
    nodeos: {
      exactName: "NodeOs",
      path: "nodeos.svg",
    },
    nodewebkit: {
      exactName: "NW.js",
      path: "nodewebkit.svg",
    },
    nwjs: {
      exactName: "NW.js",
      path: "nodewebkit.svg",
    },
    nomad: {
      exactName: "Nomad",
      path: "nomad.svg",
    },
    noysi: {
      exactName: "Noysi",
      path: "noysi.svg",
    },
    npm: {
      exactName: "npm",
      path: "npm.svg",
    },
    nuclide: {
      exactName: "Nuclide",
      path: "nuclide.svg",
    },
    numpy: {
      exactName: "NumPy",
      path: "numpy.svg",
    },
    nuxt: {
      exactName: "Nuxt.js",
      path: "nuxt.svg",
    },
    nuxtjs: {
      exactName: "Nuxt.js",
      path: "nuxt.svg",
    },
    oauth: {
      exactName: "OAuth",
      path: "oauth.svg",
    },
    ocaml: {
      exactName: "OCaml",
      path: "ocaml.svg",
    },
    octocat: {
      exactName: "Github",
      path: "github_octocat.svg",
    },
    octodns: {
      exactName: "OctoDNS",
      path: "octodns.svg",
    },
    octopusdeploy: {
      exactName: "Octopus Deploy",
      path: "octopus_deploy.svg",
    },
    olark: {
      exactName: "Olark",
      path: "olark.png",
    },
    onesignal: {
      exactName: "OneSignal",
      path: "onesignal.svg",
    },
    opengraph: {
      exactName: "Open Graph Protocol",
      path: "open_graph.svg",
    },
    opengraphprotocol: {
      exactName: "Open Graph Protocol",
      path: "open_graph.svg",
    },
    ogp: {
      exactName: "Open Graph Protocol",
      path: "open_graph.svg",
    },
    opencart: {
      exactName: "OpenCart",
      path: "opencart.svg",
    },
    opencollective: {
      exactName: "Open Collective",
      path: "opencollective.svg",
    },
    opencv: {
      exactName: "OpenCV",
      path: "opencv.svg",
    },
    opengl: {
      exactName: "OpenGL",
      path: "opengl.svg",
    },
    openlayers: {
      exactName: "OpenLayers",
      path: "openlayers.svg",
    },
    openshift: {
      exactName: "Red Hat OpenShift",
      path: "openshift.svg",
    },
    redhatopenshift: {
      exactName: "Red Hat OpenShift",
      path: "openshift.svg",
    },
    opensource: {
      exactName: "Open Source Initiative",
      path: "opensource.svg",
    },
    opensourceinitiative: {
      exactName: "Open Source Initiative",
      path: "opensource.svg",
    },
    openstack: {
      exactName: "OpenStack",
      path: "openstack.svg",
    },
    opera: {
      exactName: "Opera",
      path: "opera.svg",
    },
    opsgenie: {
      exactName: "Opsgenie",
      path: "opsgenie.svg",
    },
    optimizely: {
      exactName: "Optimizely",
      path: "optimizely.svg",
    },
    oracle: {
      exactName: "Oracle",
      path: "oracle.svg",
    },
    oreilly: {
      exactName: "O'Reilly",
      path: "oreilly.svg",
    },
    origami: {
      exactName: "Origami",
      path: "origami.svg",
    },
    origin: {
      exactName: "Origin",
      path: "origin.svg",
    },
    oshw: {
      exactName: "Open Source Hardware",
      path: "oshw.png",
    },
    opensourcehardware: {
      exactName: "Open Source Hardware",
      path: "oshw.png",
    },
    osquery: {
      exactName: "osquery",
      path: "osquery.svg",
    },
    packer: {
      exactName: "Packer",
      path: "packer.svg",
    },
    pagekit: {
      exactName: "Pagekit",
      path: "pagekit.svg",
    },
    pagekite: {
      exactName: "Pagekite",
      path: "pagekite.svg",
    },
    panda: {
      exactName: "Panda",
      path: "panda.svg",
    },
    parcel: {
      exactName: "Parcel",
      path: "parcel.svg",
    },
    parse: {
      exactName: "Parse",
      path: "parse.svg",
    },
    parsehub: {
      exactName: "ParseHub",
      path: "parsehub.svg",
    },
    passbolt: {
      exactName: "passbolt",
      path: "passbolt.svg",
    },
    passport: {
      exactName: "Passport",
      path: "passport.svg",
    },
    patreon: {
      exactName: "Patreon",
      path: "patreon.svg",
    },
    paypal: {
      exactName: "PayPal",
      path: "paypal.svg",
    },
    peer5: {
      exactName: "Peer5",
      path: "peer5.svg",
    },
    pepperoni: {
      exactName: "Pepperoni",
      path: "pepperoni.svg",
    },
    percona: {
      exactName: "Percona",
      path: "percona.svg",
    },
    percy: {
      exactName: "Percy",
      path: "percy.svg",
    },
    perfrocks: {
      exactName: "Perf.Rocks",
      path: "perf_rocks.jpeg",
    },
    perl: {
      exactName: "Perl",
      path: "perl.svg",
    },
    phalcon: {
      exactName: "Phalcon",
      path: "phalcon.svg",
    },
    phoenix: {
      exactName: "Phoenix",
      path: "phoenix.svg",
    },
    phonegapbot: {
      exactName: "PhoneGap Bot",
      path: "phonegap_bot.svg",
    },
    phonegap: {
      exactName: "PhoneGap",
      path: "phonegap.svg",
    },
    php: {
      exactName: "PHP",
      path: "php.svg",
    },
    phpstorm: {
      exactName: "PhpStorm",
      path: "phpstorm.svg",
    },
    pinterest: {
      exactName: "Pinterest",
      path: "pinterest.svg",
    },
    pipedrive: {
      exactName: "Pipedrive",
      path: "pipedrive.png",
    },
    pipefy: {
      exactName: "Pipefy",
      path: "pipefy.svg",
    },
    pivotaltracker: {
      exactName: "Pivotal Tracker",
      path: "pivotal_tracker.svg",
    },
    pixijs: {
      exactName: "PixiJS",
      path: "pixijs.svg",
    },
    pkg: {
      exactName: "pkg",
      path: "pkg.svg",
    },
    planless: {
      exactName: "Planless",
      path: "planless.svg",
    },
    plasticscm: {
      exactName: "Plastic SCM",
      path: "plastic_scm.svg",
    },
    platformio: {
      exactName: "PlatformIO",
      path: "platformio.svg",
    },
    play: {
      exactName: "Play",
      path: "play.svg",
    },
    pm2: {
      exactName: "PM2",
      path: "pm2.png",
    },
    poeditor: {
      exactName: "POEditor",
      path: "poeditor.svg",
    },
    polymer: {
      exactName: "Polymer",
      path: "polymer.svg",
    },
    postcss: {
      exactName: "PostCSS",
      path: "postcss.svg",
    },
    postgraphile: {
      exactName: "PostGraphile",
      path: "postgraphile.svg",
    },
    postgresql: {
      exactName: "PostgreSQL",
      path: "postgresql.svg",
    },
    postman: {
      exactName: "Postman",
      path: "postman.svg",
    },
    pouchdb: {
      exactName: "PouchDB",
      path: "pouchdb.svg",
    },
    preact: {
      exactName: "Preact",
      path: "preact.svg",
    },
    precursor: {
      exactName: "Precursor",
      path: "precursor.svg",
    },
    prestashop: {
      exactName: "PrestaShop",
      path: "prestashop.svg",
    },
    presto: {
      exactName: "Presto",
      path: "presto.svg",
    },
    prettier: {
      exactName: "Prettier",
      path: "prettier.svg",
    },
    prisma: {
      exactName: "Prisma",
      path: "prisma.svg",
    },
    prismic: {
      exactName: "Prismic",
      path: "prismic.svg",
    },
    processwire: {
      exactName: "ProcessWire",
      path: "processwire.svg",
    },
    productboard: {
      exactName: "Productboard",
      path: "productboard.svg",
    },
    producthunt: {
      exactName: "Product Hunt",
      path: "producthunt.svg",
    },
    progress: {
      exactName: "Progress",
      path: "progress.svg",
    },
    prometheus: {
      exactName: "Prometheus",
      path: "prometheus.svg",
    },
    promises: {
      exactName: "Promises/A+",
      path: "promises.svg",
    },
    promisesaplus: {
      exactName: "Promises/A+",
      path: "promises.svg",
    },
    proofy: {
      exactName: "Proofy",
      path: "proofy.svg",
    },
    prospect: {
      exactName: "Prospect.io",
      path: "prospect.svg",
    },
    prospectio: {
      exactName: "Prospect.io",
      path: "prospect.svg",
    },
    protactor: {
      exactName: "Protactor",
      path: "protactor.svg",
    },
    protoio: {
      exactName: "Proto.io",
      path: "protoio.svg",
    },
    protonet: {
      exactName: "Protonet",
      path: "protonet.png",
    },
    prott: {
      exactName: "Prott",
      path: "prott.png",
    },
    pug: {
      exactName: "Pug",
      path: "pug.svg",
    },
    pumpkindb: {
      exactName: "PumpkinDB",
      path: "pumpkindb.svg",
    },
    puppet: {
      exactName: "Puppet",
      path: "puppet.svg",
    },
    puppeteer: {
      exactName: "Puppeteer",
      path: "puppeteer.svg",
    },
    puppylinux: {
      exactName: "Puppy Linux",
      path: "puppy_linux.svg",
    },
    purescript: {
      exactName: "PureScript",
      path: "purescript.svg",
    },
    pushbullet: {
      exactName: "Pushbullet",
      path: "pushbullet.svg",
    },
    pusher: {
      exactName: "Pusher",
      path: "pusher.svg",
    },
    pwa: {
      exactName: "PWA",
      path: "pwa.svg",
    },
    progressivewebapps: {
      exactName: "PWA",
      path: "pwa.svg",
    },
    pycharm: {
      exactName: "PyCharm",
      path: "pycharm.svg",
    },
    python: {
      exactName: "Python",
      path: "python.svg",
    },
    pytorch: {
      exactName: "PyTorch",
      path: "pytorch.svg",
    },
    pyup: {
      exactName: "PyUp",
      path: "pyup.svg",
    },
    q: {
      exactName: "Q",
      path: "q.svg",
    },
    qlik: {
      exactName: "Qlik",
      path: "qlik.png",
    },
    qt: {
      exactName: "Qt",
      path: "qt.svg",
    },
    quarkus: {
      exactName: "Quarkus",
      path: "quarkus.svg",
    },
    quay: {
      exactName: "Quay",
      path: "quay.svg",
    },
    quobyte: {
      exactName: "Quobyte",
      path: "quobyte.svg",
    },
    quora: {
      exactName: "Quora",
      path: "quora.svg",
    },
    rlang: {
      exactName: "R",
      path: "r_lang.svg",
    },
    r: {
      exactName: "R",
      path: "r_lang.svg",
    },
    rabbitmq: {
      exactName: "RabbitMQ",
      path: "rabbitmq.svg",
    },
    rackspace: {
      exactName: "Rackspace",
      path: "rackspace.svg",
    },
    rails: {
      exactName: "Ruby On Rails",
      path: "rails.svg",
    },
    rubyonrails: {
      exactName: "Ruby On Rails",
      path: "rails.svg",
    },
    ramda: {
      exactName: "Ramda",
      path: "ramda.svg",
    },
    raml: {
      exactName: "RAML",
      path: "raml.svg",
    },
    rancher: {
      exactName: "Rancher",
      path: "rancher.svg",
    },
    raphael: {
      exactName: "Raphael",
      path: "raphael.svg",
    },
    raspberrypi: {
      exactName: "Raspberry Pi",
      path: "raspberry_pi.svg",
    },
    rax: {
      exactName: "Rax",
      path: "rax.svg",
    },
    reactrouter: {
      exactName: "React Router",
      path: "react_router.svg",
    },
    reactspring: {
      exactName: "react_spring",
      path: "react_spring.svg",
    },
    reactstyleguidist: {
      exactName: "React Styleguidist",
      path: "react_styleguidist.svg",
    },
    react: {
      exactName: "React",
      path: "react.svg",
    },
    reactivex: {
      exactName: "ReactiveX",
      path: "reactivex.svg",
    },
    realm: {
      exactName: "Realm",
      path: "realm.svg",
    },
    reapp: {
      exactName: "Reapp",
      path: "reapp.svg",
    },
    reasonml: {
      exactName: "Reason",
      path: "reasonml.svg",
    },
    reason: {
      exactName: "Reason",
      path: "reasonml.svg",
    },
    reddit: {
      exactName: "Reddit",
      path: "reddit.svg",
    },
    redhat: {
      exactName: "Red Hat",
      path: "redhat.svg",
    },
    redis: {
      exactName: "Redis",
      path: "redis.svg",
    },
    redsmin: {
      exactName: "Redsmin",
      path: "redsmin.svg",
    },
    reduxobservable: {
      exactName: "redux_observable",
      path: "redux_observable.svg",
    },
    reduxsaga: {
      exactName: "Redux_Saga",
      path: "redux_saga.svg",
    },
    redux: {
      exactName: "Redux",
      path: "redux.svg",
    },
    reindex: {
      exactName: "Reindex",
      path: "reindex.svg",
    },
    relay: {
      exactName: "Relay",
      path: "relay.svg",
    },
    require: {
      exactName: "RequireJS",
      path: "require.svg",
    },
    requirejs: {
      exactName: "RequireJS",
      path: "require.svg",
    },
    restli: {
      exactName: "Rest.li",
      path: "rest_li.svg",
    },
    rethinkdb: {
      exactName: "RethinkDB",
      path: "rethinkdb.svg",
    },
    riak: {
      exactName: "Riak",
      path: "riak.svg",
    },
    riot: {
      exactName: "Riot.js",
      path: "riot.svg",
    },
    riotjs: {
      exactName: "Riot.js",
      path: "riot.svg",
    },
    rocketchat: {
      exactName: "Rocket.Chat",
      path: "rocket_chat.svg",
    },
    rocksdb: {
      exactName: "RocksDB",
      path: "rocksdb.svg",
    },
    rollbar: {
      exactName: "Rollbar",
      path: "rollbar.svg",
    },
    rollupjs: {
      exactName: "Rollup",
      path: "rollupjs.svg",
    },
    rollup: {
      exactName: "Rollup",
      path: "rollupjs.svg",
    },
    rome: {
      exactName: "Rome",
      path: "rome.svg",
    },
    rsa: {
      exactName: "RSA",
      path: "rsa.svg",
    },
    rsmq: {
      exactName: "RSMQ(Redis Simple Message Queue)",
      path: "rsmq.svg",
    },
    redissimplemessagequeue: {
      exactName: "RSMQ(Redis Simple Message Queue)",
      path: "rsmq.svg",
    },
    rubocop: {
      exactName: "RuboCop",
      path: "rubocop.svg",
    },
    ruby: {
      exactName: "Ruby",
      path: "ruby.svg",
    },
    rubygems: {
      exactName: "RubyGems",
      path: "rubygems.svg",
    },
    rubymine: {
      exactName: "RubyMine",
      path: "rubymine.svg",
    },
    rum: {
      exactName: "Rum",
      path: "rum.svg",
    },
    runscope: {
      exactName: "Runscope",
      path: "runscope.svg",
    },
    rust: {
      exactName: "Rust",
      path: "rust.svg",
    },
    rxdb: {
      exactName: "RxDB",
      path: "rxdb.svg",
    },
    route53: {
      exactName: "Amazon Route53",
      path: "aws_route53.svg",
    },
    rds: {
      exactName: "Amazon RDS",
      path: "aws_rds.svg",
    },
    s3: {
      exactName: "Amazon S3",
      path: "aws_s3.svg",
    },
    safari: {
      exactName: "Safari",
      path: "safari.svg",
    },
    sagui: {
      exactName: "Sagui",
      path: "sagui.svg",
    },
    sails: {
      exactName: "Sails",
      path: "sails.svg",
    },
    salesforce: {
      exactName: "Salesforce",
      path: "salesforce.svg",
    },
    sameroom: {
      exactName: "Sameroom",
      path: "sameroom.svg",
    },
    sanity: {
      exactName: "Sanity.io",
      path: "sanity.png",
    },
    sanityio: {
      exactName: "Sanity.io",
      path: "sanity.png",
    },
    sassdoc: {
      exactName: "SassDoc",
      path: "sass_doc.svg",
    },
    sass: {
      exactName: "Sass",
      path: "sass.svg",
    },
    saucelabs: {
      exactName: "Sauce Labs",
      path: "saucelabs.svg",
    },
    scala: {
      exactName: "Scala",
      path: "scala.svg",
    },
    scaledrone: {
      exactName: "Scaledrone",
      path: "scaledrone.svg",
    },
    scribd: {
      exactName: "Scribd",
      path: "scribd.svg",
    },
    section: {
      exactName: "Section",
      path: "section.svg",
    },
    segment: {
      exactName: "Segment",
      path: "segment.svg",
    },
    selenium: {
      exactName: "Selenium",
      path: "selenium.svg",
    },
    semanticui: {
      exactName: "Semantic UI",
      path: "semantic_ui.svg",
    },
    semanticweb: {
      exactName: "Semantic Web",
      path: "semantic_web.svg",
    },
    semaphoreci: {
      exactName: "Semaphore CI",
      path: "semaphoreci.svg",
    },
    sencha: {
      exactName: "Sencha",
      path: "sencha.svg",
    },
    sendgrid: {
      exactName: "SendGrid",
      path: "sendgrid.svg",
    },
    seneca: {
      exactName: "Seneca",
      path: "seneca.svg",
    },
    sensu: {
      exactName: "Sensu",
      path: "sensu.svg",
    },
    sentry: {
      exactName: "Sentry",
      path: "sentry.svg",
    },
    sequelize: {
      exactName: "Sequelize",
      path: "sequelize.svg",
    },
    serverless: {
      exactName: "serverless",
      path: "serverless.svg",
    },
    sherlock: {
      exactName: "Sherlock",
      path: "sherlock.svg",
    },

    shell: {
      exactName: "Bash",
      path: "bash.svg",
    },

    shellscript: {
      exactName: "Bash",
      path: "bash.svg",
    },
    shields: {
      exactName: "Shields",
      path: "shields.svg",
    },
    shipit: {
      exactName: "Shipit",
      path: "shipit.png",
    },
    shippable: {
      exactName: "Shippable",
      path: "shippable.svg",
    },
    shogun: {
      exactName: "Shogun",
      path: "shogun.png",
    },
    shopify: {
      exactName: "Shopify",
      path: "shopify.svg",
    },
    sidekick: {
      exactName: "Sidekick",
      path: "sidekick.svg",
    },
    sidekiq: {
      exactName: "Sidekiq",
      path: "sidekiq.svg",
    },
    signal: {
      exactName: "Signal",
      path: "signal.svg",
    },
    sinatra: {
      exactName: "Sinatra",
      path: "sinatra.svg",
    },
    sitepoint: {
      exactName: "SitePoint",
      path: "sitepoint.svg",
    },
    skaffolder: {
      exactName: "Skaffolder",
      path: "skaffolder.png",
    },
    sketch: {
      exactName: "Sketch",
      path: "sketch.svg",
    },
    skylight: {
      exactName: "Skylight",
      path: "skylight.svg",
    },
    skype: {
      exactName: "Skype",
      path: "skype.svg",
    },
    slack: {
      exactName: "Slack",
      path: "slack.svg",
    },
    slides: {
      exactName: "Slides",
      path: "slides.svg",
    },
    slim: {
      exactName: "Slim",
      path: "slim.svg",
    },
    smartling: {
      exactName: "Smartling",
      path: "smartling.svg",
    },
    smashingmagazine: {
      exactName: "Smashing Magazine",
      path: "smashingmagazine.svg",
    },
    snapsvg: {
      exactName: "Snap.svg",
      path: "snap_svg.svg",
    },
    snowpack: {
      exactName: "Snowpack",
      path: "snowpack.svg",
    },
    snupps: {
      exactName: "Snupps",
      path: "snupps.svg",
    },
    snyk: {
      exactName: "Snyk",
      path: "snyk.svg",
    },
    socketio: {
      exactName: "Socket.IO",
      path: "socket_io.svg",
    },
    solarwinds: {
      exactName: "SolarWinds",
      path: "solarwinds.svg",
    },
    solid: {
      exactName: "Solid",
      path: "solid.svg",
    },
    solr: {
      exactName: "Apache Solr",
      path: "solr.svg",
    },
    sonarqube: {
      exactName: "SonarQube",
      path: "sonarqube.png",
    },
    soundcloud: {
      exactName: "SoundCloud",
      path: "soundcloud.svg",
    },
    sourcegraph: {
      exactName: "Sourcegraph",
      path: "sourcegraph.svg",
    },
    sourcetrail: {
      exactName: "Sourcetrail",
      path: "sourcetrail.svg",
    },
    sourcetree: {
      exactName: "Sourcetree",
      path: "sourcetree.svg",
    },
    spark: {
      exactName: "Spark",
      path: "spark.svg",
    },
    sparkcentral: {
      exactName: "Sparkcentral",
      path: "sparkcentral.svg",
    },
    sparkpost: {
      exactName: "SparkPost",
      path: "sparkpost.svg",
    },
    speakerdeck: {
      exactName: "Speaker Deck",
      path: "speakerdeck.svg",
    },
    speedcurve: {
      exactName: "SpeedCurve",
      path: "speedcurve.svg",
    },
    spinnaker: {
      exactName: "Spinnaker",
      path: "spinnaker.png",
    },
    splunk: {
      exactName: "Splunk",
      path: "splunk.svg",
    },
    spree: {
      exactName: "Spree",
      path: "spree.png",
    },
    spring: {
      exactName: "Spring",
      path: "spring.svg",
    },
    sqlite: {
      exactName: "SQLite",
      path: "sqlite.svg",
    },
    square: {
      exactName: "Square",
      path: "square.svg",
    },
    squarespace: {
      exactName: "Squarespace",
      path: "squarespace.svg",
    },
    stackbit: {
      exactName: "Stackbit",
      path: "stackbit.svg",
    },
    stackoverflow: {
      exactName: "Stack Overflow",
      path: "stackoverflow.svg",
    },
    stackpath: {
      exactName: "StackPath",
      path: "stackpath.svg",
    },
    stackshare: {
      exactName: "StackShare",
      path: "stackshare.svg",
    },
    statuspage: {
      exactName: "Statuspage",
      path: "statuspage.svg",
    },
    steam: {
      exactName: "Steam",
      path: "steam.svg",
    },
    stetho: {
      exactName: "Stetho",
      path: "stetho.svg",
    },
    stickermule: {
      exactName: "Sticker Mule",
      path: "stickermule.svg",
    },
    stimulus: {
      exactName: "Stimulus",
      path: "stimulus.svg",
    },
    stitch: {
      exactName: "Stitch",
      path: "stitch.svg",
    },
    stoplight: {
      exactName: "Stoplight",
      path: "stoplight.svg",
    },
    storybook: {
      exactName: "Storybook",
      path: "storybook.svg",
    },
    strapi: {
      exactName: "Strapi",
      path: "strapi.svg",
    },
    strider: {
      exactName: "Strider",
      path: "strider.svg",
    },
    stripe: {
      exactName: "Stripe",
      path: "stripe.svg",
    },
    struts: {
      exactName: "Struts",
      path: "struts.svg",
    },
    styleci: {
      exactName: "StyleCI",
      path: "styleci.svg",
    },
    stylefmt: {
      exactName: "stylefmt",
      path: "stylefmt.svg",
    },
    stylelint: {
      exactName: "Stylelint",
      path: "stylelint.svg",
    },
    stylus: {
      exactName: "Stylus",
      path: "stylus.svg",
    },
    sublimetext: {
      exactName: "Sublime Text",
      path: "sublimetext.svg",
    },
    subversion: {
      exactName: "Subversion",
      path: "subversion.svg",
    },
    sugarss: {
      exactName: "SugarSS",
      path: "sugarss.svg",
    },
    surge: {
      exactName: "Surge",
      path: "surge.svg",
    },
    survicate: {
      exactName: "Survicate",
      path: "survicate.svg",
    },
    suse: {
      exactName: "SUSE",
      path: "suse.svg",
    },
    susy: {
      exactName: "Susy",
      path: "susy.svg",
    },
    svelte: {
      exactName: "Svelte",
      path: "svelte.svg",
    },
    svg: {
      exactName: "SVG",
      path: "svg.svg",
    },
    svgator: {
      exactName: "SVGator",
      path: "svgator.svg",
    },
    swagger: {
      exactName: "Swagger",
      path: "swagger.svg",
    },
    swc: {
      exactName: "swc",
      path: "swc.svg",
    },
    swift: {
      exactName: "Swift",
      path: "swift.svg",
    },
    swiftype: {
      exactName: "Swiftype",
      path: "swiftype.svg",
    },
    symfony: {
      exactName: "Symfony",
      path: "symfony.svg",
    },
    sysdig: {
      exactName: "Sysdig",
      path: "sysdig.svg",
    },
    t3: {
      exactName: "T3",
      path: "t3.svg",
    },
    tableau: {
      exactName: "Tableau",
      path: "tableau.svg",
    },
    taiga: {
      exactName: "Taiga",
      path: "taiga.svg",
    },
    tailwindcss: {
      exactName: "Tailwind CSS",
      path: "tailwindcss.svg",
    },
    tapcart: {
      exactName: "Tapcart",
      path: "tapcart.svg",
    },
    targetprocess: {
      exactName: "Targetprocess",
      path: "targetprocess.svg",
    },
    taskade: {
      exactName: "Taskade",
      path: "taskade.svg",
    },
    tastejs: {
      exactName: "TasteJS",
      path: "tastejs.svg",
    },
    tealium: {
      exactName: "Tealium",
      path: "tealium.svg",
    },
    teamgrid: {
      exactName: "TeamGrid",
      path: "teamgrid.svg",
    },
    teamwork: {
      exactName: "Teamwork",
      path: "teamwork.png",
    },
    telegram: {
      exactName: "Telegram",
      path: "telegram.svg",
    },
    tensorflow: {
      exactName: "TensorFlow",
      path: "tensorflow.svg",
    },
    terminal: {
      exactName: "Terminal emulator",
      path: "terminal.svg",
    },
    bash: {
      exactName: "Terminal emulator",
      path: "terminal.svg",
    },
    command: {
      exactName: "Terminal emulator",
      path: "terminal.svg",
    },
    cmd: {
      exactName: "Terminal emulator",
      path: "terminal.svg",
    },
    terminalemulator: {
      exactName: "Terminal emulator",
      path: "terminal.svg",
    },
    terraform: {
      exactName: "Terraform",
      path: "terraform.svg",
    },
    terser: {
      exactName: "Terser",
      path: "terser.svg",
    },
    testlodge: {
      exactName: "TestLodge",
      path: "testlodge.svg",
    },
    tnw: {
      exactName: "TNW",
      path: "tnw.svg",
    },
    todoist: {
      exactName: "Todoist",
      path: "todoist.svg",
    },
    todomvc: {
      exactName: "TodoMVC",
      path: "todomvc.svg",
    },
    tomcat: {
      exactName: "Apache Tomcat",
      path: "tomcat.svg",
    },
    tor: {
      exactName: "Tor",
      path: "tor.svg",
    },
    torus: {
      exactName: "Torus",
      path: "torus.svg",
    },
    traackr: {
      exactName: "Traackr",
      path: "traackr.png",
    },
    trac: {
      exactName: "Trac",
      path: "trac.svg",
    },
    travisci: {
      exactName: "Travis CI",
      path: "travis_ci.svg",
    },
    treasuredata: {
      exactName: "Treasure Data",
      path: "treasuredata.svg",
    },
    treehouse: {
      exactName: "Treehouse",
      path: "treehouse.svg",
    },
    trello: {
      exactName: "Trello",
      path: "trello.svg",
    },
    typescript: {
      exactName: "TypeScript",
      path: "ts.svg",
    },
    ts: {
      exactName: "TypeScript",
      path: "ts.svg",
    },
    tsu: {
      exactName: "Tsu",
      path: "tsu.svg",
    },
    tsuru: {
      exactName: "Tsuru",
      path: "tsuru.svg",
    },
    tumblr: {
      exactName: "Tumblr",
      path: "tumblr.svg",
    },
    tunein: {
      exactName: "TuneIn",
      path: "tunein.svg",
    },
    turret: {
      exactName: "turretcss",
      path: "turret.svg",
    },
    turretcss: {
      exactName: "turretcss",
      path: "turret.svg",
    },
    twilio: {
      exactName: "Twilio",
      path: "twilio.svg",
    },
    twitch: {
      exactName: "Twitch",
      path: "twitch.svg",
    },
    twitter: {
      exactName: "Twitter",
      path: "twitter.svg",
    },
    typeform: {
      exactName: "Typeform",
      path: "typeform.svg",
    },
    typo3: {
      exactName: "TYPO3",
      path: "typo3.svg",
    },
    ubuntu: {
      exactName: "Ubuntu",
      path: "ubuntu.svg",
    },
    udacity: {
      exactName: "Udacity",
      path: "udacity.svg",
    },
    udemy: {
      exactName: "Udemy",
      path: "udemy.svg",
    },
    uikit: {
      exactName: "UIkit",
      path: "uikit.svg",
    },
    umu: {
      exactName: "UMU",
      path: "umu.svg",
    },
    unbounce: {
      exactName: "Unbounce",
      path: "unbounce.svg",
    },
    undertow: {
      exactName: "Undertow",
      path: "undertow.svg",
    },
    unionpay: {
      exactName: "UnionPay",
      path: "unionpay.svg",
    },
    unitjs: {
      exactName: "Unit JS",
      path: "unitjs.svg",
    },
    unito: {
      exactName: "Unito",
      path: "unito.svg",
    },
    unity: {
      exactName: "Unity",
      path: "unity.svg",
    },
    upcase: {
      exactName: "Upcase",
      path: "upcase.svg",
    },
    upwork: {
      exactName: "Upwork",
      path: "upwork.svg",
    },
    usertesting: {
      exactName: "UserTesting",
      path: "user_testing.svg",
    },
    uservoice: {
      exactName: "UserVoice",
      path: "uservoice.svg",
    },
    uwsgi: {
      exactName: "uWSGI",
      path: "uwsgi.svg",
    },
    v8ignition: {
      exactName: "V8 Ignition",
      path: "v8_ignition.svg",
    },
    v8turbofan: {
      exactName: "V8 TurboFan",
      path: "v8_turbofan.svg",
    },
    v8: {
      exactName: "V8",
      path: "v8.svg",
    },
    vaadin: {
      exactName: "Vaadin",
      path: "vaadin.png",
    },
    vaddy: {
      exactName: "VAddy",
      path: "vaddy.svg",
    },
    vagrant: {
      exactName: "Vagrant",
      path: "vagrant.svg",
    },
    vault: {
      exactName: "Vault",
      path: "vault.svg",
    },
    vectortimber: {
      exactName: "Vector",
      path: "vector_timber.svg",
    },
    vector: {
      exactName: "Vector",
      path: "vector_timber.svg",
    },
    vercel: {
      exactName: "Vercel",
      path: "vercel.svg",
    },
    vernemq: {
      exactName: "VerneMQ",
      path: "vernemq.svg",
    },
    vim: {
      exactName: "Vim",
      path: "vim.svg",
    },
    vimeo: {
      exactName: "Vimeo",
      path: "vimeo.svg",
    },
    visa: {
      exactName: "Visa",
      path: "visa.svg",
    },
    visaelectron: {
      exactName: "Visa Electron",
      path: "visaelectron.svg",
    },
    visualstudiocode: {
      exactName: "Visual Studio Code",
      path: "visual_studio_code.svg",
    },
    visualstudio: {
      exactName: "Visual Studio",
      path: "visual_studio.svg",
    },
    vitejs: {
      exactName: "Vite",
      path: "vitejs.svg",
    },
    vite: {
      exactName: "Vite",
      path: "vitejs.svg",
    },
    vivaldi: {
      exactName: "Vivaldi",
      path: "vivaldi.svg",
    },
    vlang: {
      exactName: "V",
      path: "vlang.svg",
    },
    v: {
      exactName: "V",
      path: "vlang.svg",
    },
    void: {
      exactName: "Void",
      path: "void.svg",
    },
    vue: {
      exactName: "Vue.js",
      path: "vue.svg",
    },
    vuejs: {
      exactName: "Vue.js",
      path: "vue.svg",
    },
    vuetify: {
      exactName: "Vuetify",
      path: "vuetifyjs.svg",
    },
    vuetifyjs: {
      exactName: "Vuetify",
      path: "vuetifyjs.svg",
    },
    vulkan: {
      exactName: "Vulkan",
      path: "vulkan.png",
    },
    vultr: {
      exactName: "Vultr",
      path: "vultr.png",
    },
    vwo: {
      exactName: "VWO",
      path: "vwo.svg",
    },
    w3c: {
      exactName: "W3C(World Wide Web Consortium)",
      path: "w3c.svg",
    },
    worldwidewebconsortium: {
      exactName: "W3C(World Wide Web Consortium)",
      path: "w3c.svg",
    },
    waffle: {
      exactName: "Waffle.io",
      path: "waffle.svg",
    },
    waffleio: {
      exactName: "Waffle.io",
      path: "waffle.svg",
    },
    wagtail: {
      exactName: "Wagtail",
      path: "wagtail.svg",
    },
    wakatime: {
      exactName: "WakaTime",
      path: "wakatime.svg",
    },
    watchman: {
      exactName: "Watchman",
      path: "watchman.svg",
    },
    wearos: {
      exactName: "Wear OS",
      path: "wearos.svg",
    },
    weave: {
      exactName: "Weaveworks",
      path: "weave.svg",
    },
    weaveworks: {
      exactName: "Weaveworks",
      path: "weave.svg",
    },
    webfundamentals: {
      exactName: "Web Fundamentals",
      path: "web_fundamentals.svg",
    },
    webdev: {
      exactName: "web.dev",
      path: "web_dev.svg",
    },
    webassembly: {
      exactName: "WebAssembly",
      path: "webassembly.svg",
    },
    webcomponents: {
      exactName: "Web Components",
      path: "webcomponents.svg",
    },
    webflow: {
      exactName: "Webflow",
      path: "webflow.svg",
    },
    webhint: {
      exactName: "webhint",
      path: "webhint.svg",
    },
    webhooks: {
      exactName: "Webhooks",
      path: "webhooks.svg",
    },
    webix: {
      exactName: "Webix",
      path: "webix.svg",
    },
    webmin: {
      exactName: "Webmin",
      path: "webmin.svg",
    },
    webpack: {
      exactName: "webpack",
      path: "webpack.svg",
    },
    webplatform: {
      exactName: "WebPlatform",
      path: "webplatform.svg",
    },
    webrtc: {
      exactName: "WebRTC",
      path: "webrtc.svg",
    },
    websocket: {
      exactName: "WebSocket",
      path: "websocket.svg",
    },
    webstorm: {
      exactName: "WebStorm",
      path: "webstorm.svg",
    },
    webtask: {
      exactName: "Webtask",
      path: "webtask.svg",
    },
    webtorrent: {
      exactName: "WebTorrent",
      path: "webtorrent.svg",
    },
    weebly: {
      exactName: "Weebly",
      path: "weebly.svg",
    },
    whalar: {
      exactName: "Whalar",
      path: "whalar.svg",
    },
    whatsapp: {
      exactName: "WhatsApp",
      path: "whatsapp.svg",
    },
    whatwg: {
      exactName: "WHATWG",
      path: "whatwg.svg",
    },
    wicket: {
      exactName: "Apache Wicket",
      path: "wicket.svg",
    },
    wifi: {
      exactName: "Wi_Fi",
      path: "wifi.svg",
    },
    wildfly: {
      exactName: "WildFly",
      path: "wildfly.svg",
    },
    wire: {
      exactName: "Wire",
      path: "wire.svg",
    },
    wix: {
      exactName: "Wix",
      path: "wix.svg",
    },
    wixcom: {
      exactName: "Wix",
      path: "wix.svg",
    },
    windows: {
      exactName: "Microsoft Windows",
      path: "microsoft_windows.svg",
    },
    woocommerce: {
      exactName: "WooCommerce",
      path: "woocommerce.svg",
    },
    woopra: {
      exactName: "Woopra",
      path: "woopra.svg",
    },
    wordpress: {
      exactName: "WordPress",
      path: "wordpress.svg",
    },
    workboard: {
      exactName: "WorkBoard",
      path: "workboard.svg",
    },
    wpengine: {
      exactName: "WPEngine",
      path: "wpengine.svg",
    },
    wufoo: {
      exactName: "Wufoo",
      path: "wufoo.svg",
    },
    xamarin: {
      exactName: "Xamarin",
      path: "xamarin.svg",
    },
    xampp: {
      exactName: "XAMPP",
      path: "xampp.svg",
    },
    xcart: {
      exactName: "X_Cart",
      path: "xcart.png",
    },
    xero: {
      exactName: "Xero",
      path: "xero.svg",
    },
    xplenty: {
      exactName: "Xplenty",
      path: "xplenty.svg",
    },
    xstate: {
      exactName: "XState",
      path: "xstate.png",
    },
    xtend: {
      exactName: "Xtend",
      path: "xtend.svg",
    },
    xwiki: {
      exactName: "XWiki",
      path: "xwiki.svg",
    },
    yahoo: {
      exactName: "yahoo!",
      path: "yahoo.svg",
    },
    yammer: {
      exactName: "Yammer",
      path: "yammer.svg",
    },
    yandexru: {
      exactName: "Yandex_russia",
      path: "yandex_ru.svg",
    },
    yarn: {
      exactName: "Yarn",
      path: "yarn.svg",
    },
    ycombinator: {
      exactName: "Y Combinator",
      path: "ycombinator.svg",
    },
    yeoman: {
      exactName: "Yeoman",
      path: "yeoman.svg",
    },
    yii: {
      exactName: "Yii",
      path: "yii.svg",
    },
    youtrack: {
      exactName: "YouTrack",
      path: "youtrack.svg",
    },
    youtube: {
      exactName: "YouTube",
      path: "youtube.svg",
    },
    zwave: {
      exactName: "Z_Wave",
      path: "z_wave.png",
    },
    zapier: {
      exactName: "Zapier",
      path: "zapier.svg",
    },
    zendframework: {
      exactName: "Zend Framework",
      path: "zend_framework.svg",
    },
    zendesk: {
      exactName: "Zendesk",
      path: "zendesk.svg",
    },
    zenhub: {
      exactName: "ZenHub",
      path: "zenhub.svg",
    },
    zeplin: {
      exactName: "Zeplin",
      path: "zeplin.svg",
    },
    zoho: {
      exactName: "Zoho",
      path: "zoho.svg",
    },
    zorinos: {
      exactName: "Zorin OS",
      path: "zorin_os.svg",
    },
    zube: {
      exactName: "Zube",
      path: "zube.svg",
    },
    zulip: {
      exactName: "Zulip",
      path: "zulip.svg",
    },
  },
};
